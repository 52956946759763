/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body, #page {
  min-height: 100%;
  min-width: 100%;
  background: #f2f2f2;

  font-family: "Helvetica Neue";
}

body {
  margin: 0;
}

#header {
  background-color: rgba(0,0,0,.97);
  text-align: center;
  font-size: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.2s;
  z-index: 10;
  color: #fff;
  font-weight: 300;
  height: 150px;
}

#header.collapsed {
  font-size: 20px;
  padding: 15px 0;
}

#content {
  background: #f2f2f2;
  padding-bottom: 16px;
  margin-top: 170px;
  transition: 0.2s;

}

#content.collapsed {
  margin-top: 75px;
}

.ps-content {
  padding: 0 0 5px 0;
}

.container {
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 8px;
  margin-right: auto;
  margin-left: auto;
}

.mat-card {
  padding: 12px !important;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.fullWidth{
  width: 100%!important;
}

button.mat-button {
  width: 125px;
  padding: 4px 20px;
  line-height: 36px;
  min-width: 125px;
}
